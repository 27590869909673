import React, { useEffect, useState } from "react";
import monitor from "../../../../assets/PungoIcons/MonitorAnimation.gif";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const AnimationSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState(t("presentationSection.txt3"));
  const [textsES] = useState(["IMPACTO", "CAMBIO", "BENEFICIO"]);
  const [textsEN] = useState(["IMPACT", "CHANGE", "BENEFITS"]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * textsES.length);
      i18n.language === "es" ? setText(textsES[randomIndex]) : setText(textsEN[randomIndex]);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [textsES, textsEN, i18n.language]);

  return (
    <>
      <section className={styles.containerAnimation} id="start">
        <PungoSectionCard title={`${t("presentationSection.title")}`} description={`${t("presentationSection.description")} ${t("presentationSection.txt1")}`} distribution="block">
          <div className={styles.txt}></div>
        </PungoSectionCard>
        <PungoSectionCard title=" " distribution="block">
          <div className={styles.textBlock}>
            <div className={styles.banner}>
              <div className={styles.mainTxt}>
                {`${t("presentationSection.txt2")}`}
                &nbsp;
                <span className={styles.highlight}>{text}</span>
                &nbsp;
                {`${t("presentationSection.txt6")}`}
              </div>
              <div className={styles.txt}>{`${t("presentationSection.txt7")}`}</div>
            </div>

            <div className={styles.flexImages}>
              <img className={styles.gif} src={monitor} alt="Monitor animation" />
            </div>
          </div>
        </PungoSectionCard>
      </section>
    </>
  );
};

export default AnimationSection;
