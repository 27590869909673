import React from "react";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoBenefitsCard2 from "../../../../pungo-ui/PungoBenefitsCard2";
import PungoBenefitsCard2Mobile from "../../../../pungo-ui/PungoBenefitsCard2Mobile";
import PungoCustomizedCarousel from "../../../../pungo-ui/PungoCustomizedCarousel";

import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "../../../../styles/export.module.scss";

import styles from "./index.module.scss";
import colors from "../../../../styles/export.module.scss";

import imgData from "../../../../assets/PungoIcons/ProcessData.svg";
import imgDesign from "../../../../assets/PungoIcons/ProcessDesign.svg";
import imgDiagnostic from "../../../../assets/PungoIcons/ProcessDiagnostic.svg";
import imgMonitor from "../../../../assets/PungoIcons/ProcessMonitor.svg";

const OurProcessSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      // minWidth: 960,
      minWidth: breakpoints.xl,
    })
  );
  


  const processData = [
    {
      id: "processStep01",
      header: t("OurProcessSection.step01.title"),
      description: t("OurProcessSection.step01.description"),
      color: colors.green2,
      image: imgData,
      imagePosition: "left" as const,
    },
    {
      id: "processStep02",
      header: t("OurProcessSection.step02.title"),
      description: t("OurProcessSection.step02.description"),
      color: colors.green4,
      image: imgDesign,
      imagePosition: "right" as const,
    },
    {
      id: "processStep03",
      header: t("OurProcessSection.step03.title"),
      description: t("OurProcessSection.step03.description"),
      color: colors.blue1,
      image: imgDiagnostic,
      imagePosition: "left" as const,
    },
    {
      id: "processStep04",
      header: t("OurProcessSection.step04.title"),
      description: t("OurProcessSection.step04.description"),
      color: colors.blue3,
      image: imgMonitor,
      imagePosition: "right" as const,
    },
  ];

  const getProcessSection = () =>
    processData.map(({ id, header, description, color, image, imagePosition }) => (
      <div key={id} className={styles.cardContainer}>
        <PungoBenefitsCard2 key={header} description={description} color={color} title={header} image={image} imagePosition={imagePosition} />
      </div>
    ));

    const getProcessSectionMobile = () =>
      processData.map(({ id, header, description, color, image, imagePosition }) => (
        <div key={id} className={styles.cardContainer}>
          <PungoBenefitsCard2Mobile key={header} description={description} color={color} title={header} image={image} imagePosition={imagePosition} />
        </div>
      ));
  

  return (
    <PungoSectionCard title={t("OurProcessSection.title")} description={`${t("OurProcessSection.description")}`} id="ourProcess">
      <div className={styles.container}>
        {desktopView ? (
          getProcessSection()
        ) : (
          <div className={styles.carouselContainer}>
            <PungoCustomizedCarousel slides={getProcessSectionMobile()} height={317} />
          </div>
        )}
      </div>
    </PungoSectionCard>
  );
};

export default OurProcessSection;
