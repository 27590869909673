import React from "react";
interface BenefitsMobileContainerProps {
  color: string;
}
const BenefitsMobileContainer: React.FC<BenefitsMobileContainerProps> = (
  props
) => (
  <svg
    width="380"
    height="400"
    viewBox="0 0 380 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    
    <path d="M250,35 H 345 V 230 L 190,290 L 35,230 V 35 H 140" stroke={props.color} strokeWidth="4" />
    <circle r="10px" cx="250" cy="35" fill={props.color} />
    <circle r="10px" cx="140" cy="35" fill={props.color} />
    
  </svg>
);

export default BenefitsMobileContainer;
